import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import WebriQForm from "@webriq/gatsby-webriq-form"

const footer = ({ location }) => {
  return (
    <footer
      className={
        location.pathname === "/the-company" ? "about-footer" : "inner-footer"
      }
    >
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5">
              <div className="footer-left-content">
                {/* <h2 className="footer-title text-d-cyan mb-3">NEWtritionIQ™</h2>
                <p className="text-d-green mb-3">
                  Donec placerat, metus sed consectetur viverra, dolor nisl
                  egestas ligula, ac gravida turpis orci eget quam. Aliquam
                  nulla nisi, accumsan id sapien varius, egestas vehicula di
                </p> */}
                {/* <ul className="mb-5">
                  <li>
                    <a href="#">
                      <StaticImage
                        placeholder="dominantColor"
                        className="img-fluid"
                        src="../../images/home2/facebook.png"
                        alt="Facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <StaticImage
                        placeholder="dominantColor"
                        className="img-fluid"
                        src="../../images/home2/twitter.png"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <StaticImage
                        placeholder="dominantColor"
                        className="img-fluid"
                        src="../../images/home2/instagram.png"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <StaticImage
                        placeholder="dominantColor"
                        className="img-fluid"
                        src="../../images/home2/youtube.png"
                        alt="Youtube"
                      />
                    </a>
                  </li>
                </ul> */}
                <p className="text-d-green mt-5">
                  ©{new Date().getFullYear()} <b>NuQ</b> All Rights Reserved.
                  Powered by{" "}
                  <b>
                    <a href="http://webriq.com/" target="_blank">
                      WebriQ
                    </a>
                    .
                  </b>
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-1"></div>
            <div className="col-lg-5 col-md-6"></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
