import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/what-is-nuq/">What is NuQ?</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/nuq-factors/">NuQ Factors</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/nuq-index/">NuQ Index</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/nuq-scores/">NuQ Scores</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog/">Blog</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/in-the-news/">In the News</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/nuq-media/">NuQ Media</Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Link to="/blog/">Blog</Link>
      </Nav.Item> */}
      <Nav.Item>
        <Link to="/the-company/">The Company</Link>
      </Nav.Item>
    </Nav>
  </div>
)
