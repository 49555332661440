import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import { stickyWrapper, isSticky, header } from "./header.module.css"
// import { StaticImage } from "gatsby-plugin-image"

const Header = ({ location }) => {
  // const image = getImage(logo)
  console.log("test1", location)
  return (
    <header
      className={location.pathname === "/" ? "homepage-header" : "inner-header"}
    >
      <Sticky
        className={stickyWrapper}
        stickyClassName={isSticky}
        stickyStyle={{ transform: "unset", zIndex: "2" }}
      >
        <div className={`header ${header}`}>
          <div className="container">
            <div className="row">
              {/* <div className="col-6 col-lg-3">
                <div className="header-logo">
                  <Link to="/">
                    <StaticImage
                      placeholder="dominantColor"
                      src="../../images/logo.png"
                      className="img-fluid h-logo"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div> */}
              <div className="col-lg-11 col-md-11 mobile-hidden ml-auto">
                <div className="header-vertical-menu align-items-center d-flex justify-content-end">
                  <Link to="/" className="header-menu-cta">
                    Home
                  </Link>
                  <Link to="/what-is-nuq" className="header-menu-cta">
                    What is NuQ
                  </Link>
                  <Link to="/nuq-factors" className="header-menu-cta">
                    NuQ Factors
                  </Link>
                  <Link to="/nuq-index" className="header-menu-cta">
                    NuQ Index
                  </Link>
                  <Link to="/nuq-scores" className="header-menu-cta">
                    NuQ Scores
                  </Link>{" "}
                  <Link to="/the-why" className="header-menu-cta">
                    The Why
                  </Link>{" "}
                  <Link to="/nuq-media" className="header-menu-cta">
                    NuQ Media
                  </Link>{" "}
                  <Link to="/in-the-news" className="header-menu-cta">
                    In the News
                  </Link>{" "}
                  <Link to="/blog" className="header-menu-cta">
                    Blog
                  </Link>
                  <Link to="/the-company" className="header-menu-cta">
                    About Us
                  </Link>
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-1 mobile-hidden">
                <div className="header-vertical-search align-items-center">
                  <button className="header-search">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 100.005 512.005 512.005"
                      height="20"
                      width="14"
                      fill="#fff"
                    >
                      <path d="M505.749 475.587l-145.6-145.6c28.203-34.837 45.184-79.104 45.184-127.317C405.333 90.926 314.41.003 202.666.003S0 90.925 0 202.669s90.923 202.667 202.667 202.667c48.213 0 92.48-16.981 127.317-45.184l145.6 145.6c4.16 4.16 9.621 6.251 15.083 6.251s10.923-2.091 15.083-6.251c8.341-8.341 8.341-21.824-.001-30.165zM202.667 362.669c-88.235 0-160-71.765-160-160s71.765-160 160-160 160 71.765 160 160-71.766 160-160 160z" />
                    </svg>
                  </button>
                </div>
              </div> */}
              <div className="col-6 sidebar-mobile-display d-none ml-auto">
                <div className="d-flex align-items-center justify-content-end">
                  {/* <div className="pr-3">
                    <button
                      className="header-search-mobile"
                      aria-label="search here"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 50.50 512.005 512.005"
                        height="54"
                        width="40"
                        fill="#fff"
                      >
                        <path d="M505.749 475.587l-145.6-145.6c28.203-34.837 45.184-79.104 45.184-127.317C405.333 90.926 314.41.003 202.666.003S0 90.925 0 202.669s90.923 202.667 202.667 202.667c48.213 0 92.48-16.981 127.317-45.184l145.6 145.6c4.16 4.16 9.621 6.251 15.083 6.251s10.923-2.091 15.083-6.251c8.341-8.341 8.341-21.824-.001-30.165zM202.667 362.669c-88.235 0-160-71.765-160-160s71.765-160 160-160 160 71.765 160 160-71.766 160-160 160z" />
                      </svg>
                    </button>
                  </div> */}
                  <div className="text-right">
                    <SideBarMenu />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}
export default Header
